import React from 'react'
import Lists from '../Lists/Lists'

function Gallery() {
  return (
    <div>
      <Lists/>
    </div>
  )
}

export default Gallery
