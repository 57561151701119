import React from 'react'
import { useEffect, useState } from "react";
import { FaAngleUp } from "react-icons/fa";
import "./ScrollToTop.css";

function ScrollToTop() {
     // The scroll-to-top button is hidden in top of the page and at start
  const [showTopButton, setShowTopButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    });
  }, []);

   const scrollToTopScreen = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // helps smooth scrolling
    });
  };
  return (
    <>
       <div className="top-to-btm">
       {showTopButton && (
        <FaAngleUp
        className="icon-position icon-style"
        onClick={scrollToTopScreen}
    />
      )}
    </div>
    </>
  )
}

export default ScrollToTop
