import React from "react";
import "./Diamond.css";
import { Zoom} from "react-awesome-reveal";

const Diamond = ({ images }) => {
  return (
    <>
      {images.map((item, index) => {
        return (
          <>
            <div
              className="col-lg-2 col-md-2 col-sm-12 col-xs-12 p-4"
              key={index}
            >
              <Zoom Cascade>
              <div className="align-content-center w-100 tech-icons">
               <a href={item.linktext}><img src={item.src} alt="dImg" className="w-100 img-fluid" /></a> 
              </div>
              </Zoom>
            </div>

          </>
        );
      })}
    </>
  );
};
export default Diamond;
