import React from "react";
import Banner from "../Banner/Banner";
import Tech from "../Tech/Tech";

function Home() {
  return (
    <>
      <div className="pt-2 mt-2"></div>
      <Banner />
      <Tech />
         </>
  );
}

export default Home;
