import React, { useRef } from "react";
import "./Contact.css";
import { FaPhone, FaLocationArrow, FaMailBulk, FaWeebly } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { Form, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

function Contact() {
  const form = useRef();
  const navigate = useNavigate();
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  });
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_6hm7ffh",
        "template_4kp8bfi",
        form.current,
        "user_3YeQdnOAz3kh9vs6h0YrF"
      )
      .then(
        navigate("/"),
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className="container-fluid contact-box text-left pt-4 mt-4">
        <div className="google-map-code embed-responsive embed-responsive-16by9">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d290.1384809355247!2d75.31565630414632!3d11.931335809111975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba4164038002bb7%3A0xf5bbcc0ac4154e62!2sW8J8%2BG7G%2C%20Azhikode%20North%2C%20Azhikode%2C%20Kerala%20670009!5e0!3m2!1sen!2sin!4v1702272958181!5m2!1sen!2sin"
            width="100%"
            height="600"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
            className="embed-responsive-item"
          ></iframe>
        </div>
      </div>
      <div className="container text-left pb-4">
        <div className="row pt-4 pb-3">
          <h1 className="headtext text-center my-3">Get in Touch
          </h1>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
            <p>
            Ready to take the plunge and start your digital journey with us? Awesome! Just drop us a line or give us a call, and we'll get the ball rolling. Whether you're ready to dive right in or just want to chat about your ideas, we're here to help. So what are you waiting for? Let's make some digital magic together! 
            </p>
            <Fade up delay={1000}>
              <h3 className="text-left mt-4 pt-4">Address</h3>
              <ul className="u-link text-left">
                <li className="pt-2">
                  {"  "}
                  <FaLocationArrow size={15} className="yellow-font" />
                  Office:Azhikode,Kannur,Kerala,India{" "}
                </li>
                <li>
                  {" "}
                  <FaPhone size={15} className="yellow-font" />
                  <a className="ml-2 u-link" href="tel:6282377311">
                    Phone No:6282377311
                  </a>
                </li>

                <li>
                  {" "}
                  <FaMailBulk size={15} className="yellow-font" />
                  <a
                    className="pt-0 u-link"
                    href="mailto:  nimblereact@gmail.com"
                  >
                    {" "}
                    nimblereact@gmail.com
                  </a>
                </li>
                <li>
                  <FaWeebly size={15} className="yellow-font" />
                  <a className="ml-2 u-link" href="http://nimblereact.com">
                    www.nimblereact.com
                  </a>
                </li>
              </ul>
            </Fade>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <p>
              Contact us today to learn more about how we can help you take your
              business to the next level.
            </p>
            <Fade down delay={1000}>
              <Form
                ref={form}
                onSubmit={sendEmail}
                className="text-light contact-form"
              >
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    className="formcontrol"
                    name="from_name"
                    type="text"
                    placeholder="Enter Name"
                    required
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="from_email"
                    className="formcontrol"
                    type="email"
                    placeholder="Enter email"
                    required
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    className="formcontrol"
                    name="subject"
                    type="text"
                    placeholder="Enter Subject"
                    required
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formGridMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    className="formcontrol pb-2"
                    placeholder="Message"
                    name="message"
                    type="text+"
                    required
                    onChange={handleChange}
                  />
                </Form.Group>

                <Button
                  variant="danger"
                  className="my-2"
                  type="submit"
                  value="Send"
                >
                 Send Message
                </Button>
              </Form>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}
export default Contact;
