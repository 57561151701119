import React from "react";
import "./ListItem.css";
import {Fade} from "react-awesome-reveal";


const ListItem = ({ images }) => {
  return (
    <>
      {images.map((item, index) => {
        return (
         <>
          
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" key={index}>
             <Fade up delay={100}>
            <div className="imgBox align-content-center p-3">
            <img src={item.src} alt={ item.index}/>
            </div>
            <h3 className="text-center">{item.headtext}</h3>
            <p>{item.text}</p>
            </Fade>
         
          </div>
         </>
        );
      })}
    </>
  );
};
export default ListItem;
