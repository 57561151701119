import "./App.css";
import Header from "./components/Header/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Home from "./components/Home/Home";
import Gallery from "./components/Gallery/Gallery";
import Services from "./components/Services/Services";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Intro from "./components/Intro/Intro";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {<Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />}></Route>
          <Route path="gallery" element={<Gallery />}></Route>
          <Route path="intro" element={<Intro />}></Route>
          <Route path="services" element={<Services />}></Route>
          <Route path="contact" element={<Contact />}></Route>
        </Routes>

        <ScrollToTop />
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
