import React from "react";
import img from "../images/abt.jpeg";
import "./AboutUs.css";
import { GiTargeting, GiEyeTarget } from "react-icons/gi";

function Aboutus() {
  return (
    <>
      <div className="container pt-4 mt-4 pb-3">
        <div className="pb-1 mb-3">
          <h1 className="text-center headtext">About Us</h1>
        </div>
        <div className="row align-items-center pb-3 mb-3">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 p-2">
            <img src={img} alt="abtimg" className="img-fluid w-100"></img>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <div className="content p-2">
              {/* <h3 className="text-left">We Aim A View For Future Generation</h3> */}
              <p class="story">
                Once upon a time, in the land of cyberspace, there was a group of digital adventurers called Nimblereact Digital Solutions. 🌐 They were like modern-day knights, helping people conquer the vast realm of the internet.
                One day, a young entrepreneur named Lily had a dream. She wanted to share her handmade crafts with the world but didn't know where to start. That's when Nimblereact appeared, offering to build her a magical website that showcased her talents.
                With their expertise, Lily's website sparkled like a treasure trove, attracting customers from far and wide. Together, they journeyed through social media forests and email valleys, spreading Lily's message to all corners of the kingdom.
                <br />Thanks to Nimblereact Digital Solutions, Lily's dream became a digital reality. And as for Nimblereact, they continued their quest, helping more dreamers turn their online fantasies into enchanted adventures.

              </p>

            </div>
          </div>
        </div>
        <div className="container-fluid about-back">
          <div className="row text-center ">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-2 p-3">
              <GiEyeTarget size={36} className="icon-font pb-1" />
              <h3>          Our Vision</h3>
              <p>
                We envision being a leading technology solutions and upskilling
                partner, driving innovation and progress. We strive for a world
                where everyone can access the tools and knowledge needed to
                succeed in the digital age.
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-3">
              <GiTargeting size={28} className="icon-font pb-1" />
              <h3>
                Our Mission
              </h3>
              <p>
                We empower individuals and businesses with technology solutions
                and upskilling programs. Our goal is to keep clients ahead in
                the digital landscape with customized software development
                services and comprehensive upskilling programs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutus;
