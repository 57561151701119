const DiamondData= [
  {
    src: require("./images/eatwell.png"),
    linktext:"https://restaunr.netlify.app/",
  },
  {
    src: require("./images/solo.png"),
    linktext:"https://travelnr.netlify.app/",
  },
  {
    src: require("./images/dress.png"),
    linktext:"https://dressappnr.netlify.app/",
  },
  
  {
    src: require("./images/eng.png"),
    linktext:"https://engnrg.netlify.app/",
  },
  {
    src: require("./images/gold.png"),
    linktext:"https://jwellery.netlify.app/",
  },
  {
    src: require("./images/pho.png"),
    linktext:"https://photographynr.netlify.app/",
  },
  {
    src: require("./images/dent.png"),
    linktext:"https://dentalclinicnr.netlify.app/",
  },
  {
    src: require("./images/alp.png"),
    linktext:"https://catechbusiness.netlify.app",
  },
  {
    src: require("./images/ih.png"),
    linktext:"https://innovatehive.in",
  },
  {
    src: require("./images/sea.png"),
    linktext:"https://seasidehutnr.netlify.app/",
  }
];
export default DiamondData;