import React from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";
import img from "./images/nrlogo.png";
import ccimg from "./images/cc.png";
import ltimg from "./images/lt.png";
import ntimg from "./images/nt.png";
import mbimg from "./images/mb.png";
import ccbimg from "./images/ccb.png";
import lnbimg from "./images/lnb.png";
import deskimg from "./images/desk.png";
import rjimg from "./images/rj.png";
import aifimg from "./images/aif.png";
import web from "./images/web.png";
import ihive from "./images/ihive.png";
import nraitools from "./images/nraitools.png";

function Header() {
  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        fixed="top"
        collapseOnSelect
        variant="light"
        className="color-nav"
      >
        <Container fluid>
          <Navbar.Brand href="#Home" className="navbrand">
            {" "}
            <img
              src={img}
              alt="hImg"
              className="navbrand img-fluid w-100"
            ></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-1 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Link to="/" className="nav-link">
                Home
              </Link>
              <Link to="/about" className="nav-link">
                About
              </Link>
              <Link to="/services" className="nav-link">
                Services
              </Link>
              {/* <Dropdown className="drop-down">
                <Dropdown.Toggle id="dropdown-basic" className="nav-link">
                  App
                </Dropdown.Toggle>
                <Dropdown.Menu className="drop-menu">
                <Dropdown.Header>
                                 <img src={web} alt="wwwImg" className="w-25 img-fluid" /> Web Designs
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    as={Link}
                    to="/gallery"
                    href="/gallery"
                  >
                    Web Development
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>
                                 <img src={mbimg} alt="mImg" className="w-25 img-fluid" /> UpComing
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    as={Link}
                    to="/intro"
                    href="/intro"
                  >
                    Mobile Dev
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>
                                 <img src={deskimg} alt="dImg" className="w-25 img-fluid" /> Softwares
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://desktopapps.vercel.app"
                  >
                    Desktop Dev
                  </Dropdown.Item>
                             
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="drop-down">
                <Dropdown.Toggle id="dropdown-basic" className="nav-link">
                  Courses
                </Dropdown.Toggle>
                <Dropdown.Menu className="drop-menu">
                    <Dropdown.Header>
                                 <img src={nraitools} alt="nraitools" className="w-25 img-fluid" /> For All
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://ai.innovatehive.in"
                  >
                   AI Tools
                  </Dropdown.Item>
                  <Dropdown.Divider />
                <Dropdown.Header>
                                 <img src={ccimg} alt="hImg" className="w-25 img-fluid" /> Trainees
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://ChaseCodecourses.vercel.app"
                  >
                    ChaseCode Courses
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>
                  <img src={ntimg} alt="hImg" className="w-25 img-fluid" />School Going
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://nextgentechclasses.netlify.app/"
                  >
                Next Gen
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>
                  <img src={ltimg} alt="hImg" className="w-25 img-fluid" /> For All Ages
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://tenhours.netlify.app/"
                  >
                    10 Hours Programme
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="drop-down">
                <Dropdown.Toggle id="dropdown-basic" className="nav-link">
                  Blog
                </Dropdown.Toggle>
                <Dropdown.Menu className="drop-menu">
                <Dropdown.Header>
                                 <img src={ihive} alt="ihive" className="w-25 img-fluid" /> Self
                      </Dropdown.Header>
                      <Dropdown.Item
                    className="nav-link"
                    href="https://innovatehive.in/"
                  >
                  Innovate Hive
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>
                                 <img src={aifimg} alt="aifmg" className="w-25 img-fluid" />  AI And Beyond
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://aifascinates.nimblereact.com/"
                  >
                    AI Fascinates
                  </Dropdown.Item>
                  <Dropdown.Divider />
                <Dropdown.Header>
                                 <img src={rjimg} alt="hImg" className="w-25 img-fluid" /> Life
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://revive.nimblereact.com/"
                  >
                    Revive N Jazz Up
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>
                                 <img src={lnbimg} alt="hImg" className="w-25 img-fluid" /> Business
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://leadsnbots.nimblereact.com/"
                  >
                    Leads N Bots
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>
                                 <img src={ccbimg} alt="hImg" className="w-25 img-fluid" /> Dev
                      </Dropdown.Header>
                  <Dropdown.Item
                    className="nav-link"
                    href="https://chasecode.nimblereact.com/"
                  >
                  Chase Code
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <Link to="/contact" className="nav-link">
                Contacts
              </Link>
                           </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br></br>
      <br></br>
    </>
  );
}

export default Header;
