import React, { Component } from "react";
import ListItem from "../ListsAi/ListItem/ListItem";
import ListsAiData from "../ListsAi/ListsAiData";
import "../Intro/Intro.css";
import "./ListAi.css";

function ListAi() {
  return (
    <>
      <div className="container service-back">
        <h1 className="text-center py-4 my-4 headtext"> Benefits With Our Features</h1>
        <p > You have a dream, a passion burning inside you, but the digital world feels like a labyrinth. Enter <strong className="half-bg2">NimbleReact Digital Solutions, your digital dream team!</strong></p>
        <div className="row flex justify-content-center align-items-center">
          <ListItem images={ListsAiData} />
        </div>
      </div>
    </>
  );
}

export default ListAi;
