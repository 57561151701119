import React from "react";
import DiamondData from "../Diamond/DiamondData";
import Diamond from "../Diamond/Diamond";

function Lists() {
   return (
    <div className="container-fluid gallery-back pt-4 mt-4">
       <h1 className="pt-4 mt-4 headtext text-center">What We Do</h1>
      <h6 className="text-center">To View More Click on Images</h6>
      <div className="row pt-2">
        <Diamond images={DiamondData} />
        </div>
    </div>
  );
}

export default Lists;
