import React from "react";
import "./Banner.css";
import { Slide, Fade, Zoom } from "react-awesome-reveal";
import SlideTime from "../SlideTime/SlideTime";
import img from "./images/banner.jpeg";
import Testimonials from "../Testimonials/Testimonials";
import TestimonialsData from "../Testimonials/TestimonialsData/TestimonialsData";

function Banner() {

  return (
    <>
      <div className="container-fluid banner-back pb-3 mt-2">
        <div className="banner-container flex justify-center align-items-center py-4">
          <div className="row flex justify-center align-items-center my-4">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-4">
              <div className="headers flex justify-center align-items-center p-4 m-2">
                <Slide Left delay={1000}>
                  <h6 className="title-sub-text half-bg1">
                    Get Noticed In Web
                  </h6>
                </Slide>
                <Zoom Right delay={1000}>
                  <h1 className="title-text pb-3">
                    welcome to our    <span>cozy corner</span> of the internet!
                  </h1>
                </Zoom>
                <Fade Left delay={1000}>
                  <p>
                    🌟 At NimbleReact, we're all about making your online dreams come true.
                    Whether you're a small business owner, a budding blogger, or a creative
                    soul with big ideas, we've got your back.
                  </p>
                </Fade>
                <Fade Up delay={2000}>
                  <a href="tel:6282377311" data-title="Call NOw">
                    Call NOw
                  </a>
                </Fade>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pt-3 mt-2">
              <img src={img} alt="bannerimg" className="img-fluid w-100"></img>
              {/* <Fade delay={1000}>
                <ReactPlayer
                  loop
                  url="https://www.youtube.com/watch?si=-MQSwWqgdm7ZfB6Y&v=WzERxzXxjF8&feature=youtu.be"
                  playing={true}
                  width="100%"
                  height="350px"
                  controls={true}
                  onPlay={() => setPlaying(true)}
                  onPause={() => setPlaying(false)}
                />
              </Fade> */}
            </div>
          </div>
        </div>
      </div>
      <SlideTime />
      <h1 className="text-center m-3 p-3">Our Happy Customers</h1>
      <div className="container-fluid">
      <div className="row flex justify-content-center align-items-center">
      <Testimonials images={TestimonialsData} />
      </div>
      </div>
     
    </>
  );
}

export default Banner;
