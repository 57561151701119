import React, { Component } from 'react'

import ListItem from "../ListItem/ListItem";
import ListData from '../ListData/ListData';
import "../Intro/Intro.css";
import ParticlesBg from 'particles-bg'

function Intro() {
  let config = {
    num: [4, 17],
    rps: 0.1,
    radius: [3, 8],
    life: [1.5, 3],
    v: [2, 3],
    tha: [-40, 40],
    // body: "./images/elegant-grid.png", // Whether to render pictures
    // rotate: [0, 20],
    alpha: [0.6, 0],
    scale: [1, 0.1],
    position: "all", // all or center or {x:1,y:1,width:100,height:100}
    color: ["random", "#ff0000"],
    cross: "bround", // cross or bround
    random: 25,  // or null,
    g: 5,    // gravity
    f: [2, -1], // force
    onParticleUpdate: (ctx, particle) => {
        ctx.beginPath();
        ctx.rect(particle.p.x, particle.p.y, particle.radius * 2, particle.radius * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
        ctx.closePath();
    }
  };
  return (
   <>
       <div className="container-fluid">
      <ParticlesBg type="custom" config={config} bg={true} />
           <h1 className="text-center py-4 my-4">Coming Soon</h1>
         <div className="row flex justify-content-center align-items-center">
               <ListItem images={ListData} />
        </div>
             </div>
    
      </>
     )
}

export default Intro
