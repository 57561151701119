import React from "react";
import "./Footer.css";
import { FaPhone, FaLocationArrow, FaMailBulk, FaWeebly } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import {
  FaFacebookSquare,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
  FaDiscord,
  FaPinterest,
} from "react-icons/fa";
function Footer() {
  return (
    <>
      <div className="container-fluid footer-box">
        <div className="footer-overlay px-0">
          <Fade Up delay={1000}>
            <div className="row p-3">
              <div className="col-md-5 col-sm-12 col-xs-12">
                <h5 className="footer-sub-head">About Nimble React</h5>
                <p>
                  Nimble React is an Enterprise born out of Passion.It aims at
                  providing maximum fast and secure data to its customers.
                </p>
                <p>
                  To learn more about Nimble React, please{" "}
                  <a href="mailto:  nimblereact@gmail.com">
                    <span className="yellow-font">Contact Us </span>
                  </a>
                  today
                </p>
                <a href="https://privacypolicies.nimblereact.com/">
                  Privacy Policy
                </a>
                <br />
                <a href="https://termsofservice.nimblereact.com/">
                  Terms Of Service
                </a>
              </div>
              <div class="col-md-2 col-sm-12 col-xs-12">
                <h5 className="footer-sub-head">Blog Links</h5>
                <ul className="u-link pl-0 py-0">


                  <li>
                    {" "}
                    <a href="https://chasecode.nimblereact.com/">
                      ChaseCode Blog
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="https://revive.nimblereact.com/">ReviveNJazzup</a>
                  </li>

                  <li>
                    {" "}
                    <a href="https://leadsnbots.nimblereact.com/">LeadNBots</a>
                  </li>
                </ul>
              </div>
              <div class="col-md-2 col-sm-12 col-xs-12">
                <h5 className="footer-sub-head">Apps</h5>
                <ul className="u-link pl-0">
                  <li>
                    {" "}
                    <a href="https://desktopapps.vercel.app">Desktop Apps</a>
                  </li>

                  <li>
                    {" "}
                    <a href="https://digitappsblog.vercel.app">Mobile Apps</a>
                  </li>
                </ul>
                <h5 className="footer-sub-head">Courses</h5>
                <ul className="u-link pl-0">
                  <li>
                    {" "}
                    <a href="https://chasecodecourses.vercel.app">ChaseCode</a>
                  </li>

                  <li>
                    {" "}
                    <a href="https://nextgentechclasses.netlify.app/">
                      NextGen Tech
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a href="https://tenhours.netlify.app/">
                      Ten Hours
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-12 col-xs-12">
                <h5 className="footer-sub-head">GET In TOUCH</h5>

                <ul className="u-link pl-0">
                  <li className="pt-2">
                    {"  "}
                    <FaLocationArrow size={15} className="yellow-font" />
                    Office:Azhikode, Kannur,Kerala,India{" "}
                  </li>
                  <li>
                    {" "}
                    <FaPhone size={15} className="yellow-font" />
                    <a className="ml-2 u-link" href="tel:6282377311">
                      6282377311
                    </a>
                  </li>

                  <li>
                    {" "}
                    <FaMailBulk size={15} className="yellow-font" />
                    <a
                      className="pt-0 u-link"
                      href="mailto:  nimblereact@gmail.com"
                    >
                      {" "}
                      nimblereact@gmail.com
                    </a>
                  </li>
                  <li>
                    <FaWeebly size={15} className="yellow-font" />
                    <a className="ml-2 u-link" href="http://nimblereact.com">
                      www.nimblereact.com
                    </a>
                  </li>
                </ul>
                <a href="https://facebook.com/nimblereact" class="px-2">
                  <FaFacebookSquare size={25} />
                </a>
                <a href="https://wa.me/+916282377311" class="px-2">
                  <FaWhatsapp size={25} />
                </a>
                <a
                  href="https://www.linkedin.com/in/nimble-react-45586a235/"
                  class="px-2"
                >
                  <FaLinkedin size={25} />{" "}
                </a>
                <a href="https://www.instagram.com/nimblereact/" class="px-2">
                  <FaInstagram size={25} />
                </a>
                <a href="https://discord.gg/UWMvyVgk8C" class="px-2">
                  <FaDiscord size={25} />
                </a>
                <a href="https://twitter.com/NimbleReact" class="px-2">
                  <FaTwitter size={25} />
                </a>
                <a href="https://in.pinterest.com/nimblereact/" class="px-2">
                  <FaPinterest size={25} />
                </a>
                <a href="https://www.youtube.com/@NimbleReact" class="px-2">
                  <FaYoutube size={25} />
                </a>
                <a
                  href="https://www.youtube.com/@NimbleReactMalayalam-tq2ot"
                  class="px-2"
                >
                  <FaYoutube size={25} />
                </a>
              </div>
            </div>
          </Fade>
        </div>
        <div className="row pt-2 footer-overlay">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 py-2">
            <h6 className="text-center">
              All Rights Reserved To @{" "}
              <a href="http://nimblereact.com">2024 Nimble React</a>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
