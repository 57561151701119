import React from "react";
import "./Services.css";
import { FaConnectdevelop, FaDesktop, FaSchool } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import { MdDomain, MdMobileScreenShare, MdWeb } from "react-icons/md";

function Services() {
  return (
    <>
      <div className="container-fluid service-back container-service flex justify-center align-items-center pt-4 mt-4">
       
        <h1 className="pt-4 mt-4 text-center headtext">Our Services

        </h1>

        <p>Ready to level up your online presence? Here's where the magic happens! From jaw-dropping website design to savvy social media strategies, we offer a range of services to suit your needs. Whether you're looking to launch a brand-new website or give your existing one a makeover, we've got you covered. And when it comes to digital marketing, we've got the skills to help you stand out in a crowded digital landscape.
        </p>
        <Fade up delay={1000}>
          <div className="row flex justify-center align-items-center p-3 text-center">
          <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3">
              <div className="box">
                <MdDomain
                  size={"3em"}
                  style={{ color: "var(--style-color)" }}
                  className="fa-icon"
                />
                <h3>Digital Marketing</h3>
                <p>
                  Our digital marketing service is your ticket
                  to online success. It's the art of reaching, engaging, and converting audiences 
                  in the digital realm. Whether you're a small business or a
                  growing brand, our digital marketing solutions are designed to help you stand out
                  in a crowded digital landscape.With the right digital marketing strategy, the possibilities are endless!
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3">
              <div className="box">
                <MdWeb
                  size={"3em"}
                  style={{ color: "var(--style-color)" }}
                  className="fa-icon"
                />
                <h3>Web Development</h3>
                <p>
                  Our team of experienced web developers creates cutting-edge
                  websites using the latest technologies and frameworks. We work
                  closely with our clients to understand their business needs
                  and create a website that aligns with their goals. Our web
                  development services include custom website development and
                  website maintenance.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3">
              <div className="box">
                <MdMobileScreenShare
                  size={"3em"}
                  style={{ color: "var(--style-color)" }}
                  className="fa-icon"
                />
                <h3>App Development</h3>
                <p>
                  With the increasing use of smartphones, having a mobile app is
                  essential for any business. Our team of skilled mobile app
                  developers creates apps that are user-friendly and easy to
                  navigate. Our services include custom mobile app development,
                  iOS app development, Android app development, and mobile app
                  maintenance.
                </p>
              </div>
            </div>
          
          </div>
        </Fade>
        <Fade Down delay={1000}>
          <div className="row p-3 text-center">
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3">
              <div className="box">
                <FaSchool
                  size={"3em"}
                  style={{ color: "var(--style-color)" }}
                  className="fa-icon"
                />
                <h3>Upskilling Programs</h3>
                <p>
                  We offer upskilling programs for aspiring developers, covering
                  programming languages like Python, PHP, and JavaScript their
                  frameworks, along with web and mobile app development, and
                  software development courses. Our experienced trainers provide
                  hands-on training for in-depth understanding.
                </p>
              </div>
            </div>
          
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3">
              <div className="box">
                <FaDesktop
                  size={"3em"}
                  style={{ color: "var(--style-color)" }}
                  className="fa-icon"
                />
                <h3>Desktop Softwares</h3>
                <p>
                  Our desktop software development services cater to businesses
                  that require custom software for their operations. Our
                  developers have expertise in developing software for various
                  industries. We use the latest technologies to ensure that our
                  software is efficient, reliable, and scalable.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12 mb-3">
              <div className="box">
                <FaConnectdevelop
                  size={"3em"}
                  style={{ color: "var(--style-color)" }}
                  className="fa-icon"
                />
                <h3>AI Automation </h3>
                <p>
                  Unleash the power of artificial intelligence to streamline
                  processes and elevate customer experiences.
                  Our chatbots are designed with small and medium businesses in mind,
                  so you can get the support you need.
                  Don't let customer
                  support and onboarding hold you back - let our AI automation
                  chatbot service help you grow your business!
                </p>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default Services;
