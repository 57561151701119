const ListData = [
  {
    src:require('./img/3.png'),
    headtext:"Stunning Websites",
   
    text: "Your online presence becomes a dazzling showcase, attracting customers like bees to honey."   
   },
  {
    src:require('./img/1.png'),
    headtext:"Tailored Strategies",
    text: "Say goodbye to cookie-cutter approaches; Nimblereact crafts personalized plans to suit your unique goals and vision."
   },
   
    {
      src:require('./img/2.png'),
      headtext:"Social Media Mastery",
      
      text: "Navigate the social seas with ease, building a loyal following and spreading your message far and wide."
     },
     
    
     
  ]
  export default ListData;