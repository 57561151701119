const TestimonialsData = [
    {
      src:require('./img/1.jpeg'),
      headtext:"Zavier, Small Business Owner",
      text: "Working with Nimblereact was a game-changer for my blog. They helped me revamp my website and develop a solid social media strategy. I've seen a significant increase in traffic and engagement ever since!"
   
     },
    {
      src:require('./img/2.jpeg'),
      headtext:"Sharan, Blogger",
      text: "I can't recommend Nimblereact enough! Their personalized approach and attention to detail truly set them apart. They took the time to understand my goals and delivered results that exceeded my expectations. If you're looking to elevate your online presence, look no further!"
     },
     
      {
        src:require('./img/3.jpeg'),
        headtext:"Jeevan Creative Entrepreneur",
        text: "Nimblereact exceeded all my expectations! Their team not only built me a stunning website but also provided invaluable insights into digital marketing. Thanks to them, my online presence has never been stronger!"

       },
       
      
       
    ]
    export default TestimonialsData;