import React from 'react'
import TechData from '../Tech/TechData'
import Diamond from '../Diamond/Diamond'
import "./Tech.css"
function Tech() {
  return (
    <>
       <div className="container-fluid tech-back">
        <h1 className="headtext text-center py-3 ">Technologies We Use</h1>
        <div className="row pt-2">
          <Diamond images={TechData} />
        </div>
      </div>
    </>
  )
}

export default Tech
