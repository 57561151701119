import React from "react";
// import SlideTimeData from "../SlideTimeData/SlideTimeData";
// import Slider from "../Slider/Slider";
import "./SlideTime.css"
import { useState } from "react";
import ListsAi from "../ListsAi/ListAi"
import ReactPlayer from "react-player";
function SlideTime() {
  const [isPlaying, setPlaying] = useState(false);
  return (
    <>
      <div className="container p-4">
        <h1 className="text-center py-3">Here Comes Your Solutions</h1>
        <div className="row align-items-center pt-0">
          <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
            {/* <Slider images={SlideTimeData} /> */}
            <ReactPlayer
              loop
              url="https://www.youtube.com/watch?si=-MQSwWqgdm7ZfB6Y&v=WzERxzXxjF8&feature=youtu.be"
              playing={true}
              width="100%"
              height="350px"
              controls={true}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
            />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
            <p className="half-bg">Ever felt lost in the maze of the internet? 🌐 </p>
            <p>
              <strong>Recognize Your Problem:</strong> Struggling to stand out online? Feeling overwhelmed by the vast world wide web?

            </p>
            <p><strong>We Comes With Solution: </strong>Enter Nimblereact! We're here to guide you through the digital jungle, from crafting captivating websites to navigating social media streams.
            </p>
            <p>With Nimblereact by your side, you'll conquer the online realm with ease.
               Say goodbye to confusion and hello to clarity!
            </p>

          </div>
        </div>
        <ListsAi />
      </div>
    </>
  );
}

export default SlideTime;
