import React from 'react'
import {Fade} from "react-awesome-reveal";
import "./Testimonials.css"

const Testimonials = ({ images }) => {
    return (
      <>
        {images.map((item, index) => {
          return (
           <>
                 <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-4 m-4" key={index}>
               <Fade up delay={100}>
              <div className="imgBox rounded-circle overflow-hidden align-content-center">
               <img src={item.src} alt={ item.index}/>
              </div>
              <h3 className="text-center">{item.headtext}</h3>
              <p className='text-center'>🌟🌟🌟🌟🌟</p>
              <p>{item.text}</p>
            
              </Fade>
           
            </div>
           </>
          );
        })}
      </>
    );
  };

export default Testimonials

