import React from "react";
import ReactPlayer from "react-player";
import { useState } from "react";
import {Fade} from "react-awesome-reveal";

function AboutusSlide() {
  const [isPlaying, setPlaying] = useState(false);
  return (
    <>
      <div className="container">
               <div className="row align-items-center p-2">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <h1 className="pt-4 mt-4 text-center headtext">Why Choose Us
        </h1>
        <p>So why should you choose Nimblereact? Well, besides our killer design skills and top-notch marketing know-how, we're also all about personalized service. We take the time to understand your unique goals and vision, tailoring our solutions to suit your needs. Plus, we're always here to lend a helping hand and answer any questions you may have along the way.
        </p>
                       <h1 className="text-center py-3 headtext">Who We Are
                       </h1>
            <p>
            Curious about who we are and what makes us tick? Well, wonder no more! We're a team of digital enthusiasts, web wizards, and marketing maestros who are passionate about helping you succeed online. With years of experience under our belts and a knack for creativity, we're here to turn your digital dreams into reality.

            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <Fade delay={1000}>
              <ReactPlayer
                loop
                url="https://youtu.be/weG7rqjAdYQ"
                playing={true}
                width="100%"
                height="300px"
                controls={true} 
               onPlay={() => setPlaying(true)}
               onPause={() => setPlaying(false)}
              />
              </Fade>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutusSlide;
