const TechData=[
   
    {
      src: require("./images/mern.png"),
     
    },
    {
      src: require("./images/sss.png"),
      
    },
    {
      src: require("./images/lara.png"),
      
    },
    {
      src: require("./images/fl.png"),
     
    },
    {
      src: require("./images/dj.png"),
     
    },
   {
      src: require("./images/qua.png"),
     
    },
    {
      src: require("./images/next.png"),
      
    },
        {
      src: require("./images/tw.png"),
      
    },
    {
      src: require("./images/boot.png"),
      
    },
   
    {
      src: require("./images/vue.png"),
     
    },
         {
      src: require("./images/mui.png"),
     
    },
   
    {
      src: require("./images/vb.png"),
      
    },
         
  ];
  export default TechData;