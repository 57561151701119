const ListData = [
  {
    src:require('./img/tg.png'),
    headtext:"The Gifted Blog",
    linktext:"https://thegifted.netlify.app",
    text: "A Complete Blog App For All From All on all categories to make every god gifted thoughts known."   
   },
  {
    src:require('./img/es.png'),
    headtext:"EcomStore App",
    linktext:"https://ecomstores.netlify.app",
    text: "An E-commerce app suitable for all online products sale with delivery tracking and stock management."
   },
   {
    src:require('./img/ts.png'),
    headtext:"To Stores App",
    linktext:"https://tostores.netlify.app",
    text: "An Online Grocery Delivery app suitable for all stores sale with delivery tracking and stock management."
   },
  {
   src:require('./img/marb.png'),
   headtext:"Marine Bill App",
   linktext:"https://marinebill.netlify.app",
   text: "A community stock data management system and Personal Accounting Billing app for Fishing Trade."
  },
  {
   src: require('./img/chase.png'),
   headtext:"Chase code App",
   linktext:"https://chasecode.netlify.app",
   text: "A Coding App With Free and Paid Courses with a simple elegant course structure and tests."
  },
    {
    src:require('./img/sitebill.png'),
    headtext:"Site Bill",
    linktext:"https://sitebill.netlify.app",
    text:"A complete debit credit daybook system for site contractors to track their daily money activities."
   },
   {
    src:require('./img//mb.png'),
    headtext:"Market Bill",
    linktext:"https://marketbill.netlify.app",
    text:"A complete debit credit daybook system for tracking purchase and sales of market sales system."
   },
   {
    src:require('./img/marinedel.png'),
    headtext:"Marine Delivery",
    linktext:"https://marinedelivery.netlify.app",
    text:"A fish Delivery app to track fresh fish availability in a region and it reach with maximum speed in every locality."
   }
   
]
export default ListData;