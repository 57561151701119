import React from "react";
import Aboutus from "./Aboutus/Aboutus";
import AboutusSlide from "./AboutusSlide/AboutusSlide";
import { Fade } from "react-awesome-reveal";
function About() {
  return (
    <>
    <Fade up delay={1000}>
      <Aboutus />
      </Fade>
      <Fade left delay={1000}>
      <AboutusSlide />
      </Fade>
    </>
  );
}

export default About;
